import { Image } from 'react-bootstrap';
import PropTypes from 'prop-types'
import FormInput from './FormInput';

const SettingBkImages = ( { DeviceSetting, onSettingChange } ) => {
    
    const setSetting = (changedSettings) => {
        var tempSettings = [DeviceSetting, ];
        
        changedSettings.forEach(({setting, value}) => {
            try {
                // Change the setting
                tempSettings[0][setting] = value;
            } catch (error) {
                console.log('ERROR: getSetting', setting, DeviceSetting, value, error);
            }
        });
        // call the Change State
        onSettingChange( tempSettings );
    }

    const imageList = [];
    for (let index = 1; index <= 20; index++) {
        imageList.push(index);
    }
    
    const onBkImageToggle = (e, index) => {
        let retValue = '';
        let bkArray = DeviceSetting['background-images'].split(',');

        // Remove the last empty element
        bkArray = bkArray.filter(e => (e!==''));

        // Add or remove the item (index)
        bkArray = bkArray.filter(e => (`${e}` !== `${index}`));
        e && (bkArray.push(index));

        // Update the Setting Change
        bkArray.sort((a, b) => {
                        return a - b;
                    });
        retValue = bkArray.toString() + ',';    // Adding trailing ,
        let settingArr = [{setting: 'background-images', value: `${retValue}`}];
        setSetting( settingArr );
    }

    return (
        <>
        { DeviceSetting.device_id !== 0 && 
            <>
            <div className="container-fluid row p-0">
                {imageList.map( (i) => (
                    <div className="col border-bottom p-2" key={`div-${DeviceSetting.device_id}-${i}`}>
                        <FormInput 
                            name={'background-' + i}
                            labelText=''
                            inputType="checkbox"
                            value={DeviceSetting['background-images'].includes(`${i},`)}
                            onValueChange={(e) => onBkImageToggle(e, i)} />
                        <Image 
                            className="m-3 float-right"
                            src={process.env.REACT_APP_IMAGES_URL + '/' + i + '.jpg'} 
                            style={{width:'200px'}} rounded />
                    </div>
                ) ) }
            </div>
            </>
        }
        </>
    )
}

SettingBkImages.propTypes = {
    DeviceSetting: PropTypes.object.isRequired,
    onSettingChange: PropTypes.func.isRequired,
}

export default SettingBkImages

