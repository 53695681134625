import PropTypes from 'prop-types';
import { useState } from 'react';
import { Form } from 'react-bootstrap';

const FormInput = ({ name, labelText, value, inputType, maxLen, onValueChange, options, isValid, disabled }) => {
    
    const [fieldData, setFormData] = useState(value);
    var selectedItem = '';
    if (options) {
        let filteredArr = options.filter((option) => `${option.id}` === `${fieldData}`);
        try {
            selectedItem = filteredArr[0].id;
        } catch(error) {
        }
    }

    const dataChanged = (v) => {

        value = v;
        setFormData(value);
        onValueChange(value);
    }
    
    return (
        <>
            { (inputType === 'select') ?
            <>
                <Form.Group>
                    { (labelText !== '') &&
                        <Form.Label>{labelText}</Form.Label>}
                    <Form.Control
                            as = 'select'
                            id = {name}
                            name = {name}
                            label = {labelText}
                            placeholder = {"Choose " + labelText}
                            onChange = {(e) => dataChanged(e.currentTarget.value)}
                            disabled = {disabled} 
                            isValid = {isValid}
                            value = {selectedItem}
                            custom >
                        {options.map((e, key) => {
                            return <option key={key} value={e.id}>{e.description}</option>
                        })}
                    </Form.Control>
                </Form.Group>
            </>
            :  (inputType === 'checkbox') ?
            <>
                <Form.Group>
                    <Form.Check 
                        type={'switch'}
                        id={name}
                        name={name}
                        label={labelText}
                        defaultChecked = {fieldData}
                        onChange={(e) => dataChanged(e.currentTarget.checked)}
                        disabled = {disabled} 
                        isValid={isValid}
                        />
                </Form.Group>
            </>
            :
            <>
                <Form.Group>
                    { (labelText !== '') &&
                        <Form.Label>{labelText}</Form.Label>}
                    <Form.Control 
                        type = {inputType}
                        name={name}
                        id={name}
                        placeholder={"Enter " + labelText + " here"}
                        defaultValue={fieldData}
                        maxLength={maxLen}
                        onChange={(e) => dataChanged(e.currentTarget.value)}
                        disabled = {disabled} 
                        isValid={isValid}
                    />
                </Form.Group>
            </>
            }
        </>
    )
}

FormInput.defaultProps = {
    labelText: '',
    value: '',
    inputType: 'text',
    options: [{id: '', description: ''}],
    maxLen: 10,
    disabled: false,
}

// Prop Types
FormInput.propTypes = {
    name: PropTypes.string.isRequired,
    labelText: PropTypes.string,
    value: PropTypes.any,
    inputType: PropTypes.string.isRequired,
    maxLen: PropTypes.number,
    options: PropTypes.array,
    onValueChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
}
export default FormInput;