import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormInput from '../components/FormInput';
import SettingAzan from "../components/SettingAzan";
import SettingQuran from "../components/SettingQuran";
import SettingBkImages from "../components/SettingBkImages";
import SettingLocation from '../components/SettingLocation';
import { getData, postData } from '../global/func';
import appIcon from "../assets/images/favicon-32x32.png";

import 'react-h5-audio-player/lib/styles.css';

const Settings = ({ User, Devices }) => {

    // DeviceSettings State
    const [deviceSettings, setDeviceSettings] = useState();
    const [activeDevice, setActiveDevice] = useState(0);
  
    useEffect(() => {
        
        // Get the device settings
        const getDeviceSettings = async () => {
            const sAuthData = {
                DEVICE_KEY: Devices[activeDevice].device_key,
                SESSION_ID: process.env.REACT_APP_SESSION_ID,
            };

            
            // Get Device Settings from API
            const url = encodeURI(process.env.REACT_APP_API_SERVER_URL + '/settings/');
            const devSettings = await getData(url, sAuthData, sAuthData);
    
            // Update Device Setting State
            const newDeviceSettings = {...devSettings[0]};
            
            setTimeout(() => {
                setDeviceSettings( newDeviceSettings );
            }, 1000);
        }

        // Get the Device List
        getDeviceSettings();
    
    }, [activeDevice, Devices]);
    
    const onSettingChange = (settings) => {

        // Save to DB here
        const sAuthData = {
            DEVICE_KEY: Devices[activeDevice].device_key,
            SESSION_ID: process.env.REACT_APP_SESSION_ID,
        };
        
        // Get Device Settings from API
        const settings_url = encodeURI(process.env.REACT_APP_API_SERVER_URL + '/settings/update.php');
        postData(settings_url, settings[0], sAuthData);

        // Set the state
        setDeviceSettings( settings[0] );
    }

    const onDeviceChange = (e) => {
        var index = -1;

        index = Devices.map(i => (i.id)).indexOf(e);
        if (index !== -1) {
            setActiveDevice( index );
        }
    }

    const devicesList = Devices.map(e => {
        return {id: e.id, description: e.serial_no + ' | ' + e.expires}
    });

    return (
        (deviceSettings) ?
        <>
        <div className="col-xs-12 col-lg-7 mx-auto">
            <div className="card">
                <div className="card-header">Welcome back, {User.name}
                    <span className="float-right"><img alt="WIKI Mosque Logo" width={"50px"} src={appIcon} /></span>
                </div>
                <div className="card-body p-3">
                    <h5 className="p-2"><i className="lni lni-cog"></i> PrayerTimes Settings</h5>
                    <div className="card-header">Device</div>
                    <div className="card-body p-3">
                        <div className="form-row">
                            <div className="col mb-3">
                                <FormInput name="device-id" 
                                        labelText="Your Devices" 
                                        inputType="select"
                                        value={devicesList[activeDevice].id}
                                        options = {devicesList}
                                        onValueChange={onDeviceChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            {deviceSettings.device_id !== 0 && 
                            <>
                            <SettingLocation 
                                DeviceKey = {Devices[activeDevice].device_key}
                                DeviceSetting = {deviceSettings}
                                onSettingChange={onSettingChange} />
                            </>
                            }
                        </div>
                    </div>
                    <div className="card-header">Device Settings</div>
                    <div className="card-body p-0">
                        {deviceSettings.device_id !== 0 && 
                        <>
                        <SettingAzan 
                            DeviceSetting = {deviceSettings}
                            onSettingChange={onSettingChange} />
                        </>
                        }
                        {deviceSettings.device_id !== 0 && 
                        <>
                        <SettingQuran 
                            DeviceSetting = {deviceSettings}
                            onSettingChange={onSettingChange} />
                        </>
                        }
                        {deviceSettings.device_id !== 0 && 
                        <>
                        <div className="card col-sm-12 p-0">
                            <div className="card-header">Background Images</div>
                            <div className="card-body form-row">
                            <SettingBkImages
                                DeviceSetting = {deviceSettings}
                                onSettingChange = {onSettingChange} />    
                            </div>
                        </div>
                        </> 
                        }
                    </div>
                </div>
            </div>
        </div>
        </>
        :
        <>
        <div>
            <h1>Loading ....</h1>
        </div>
        </>
    )
}

Settings.defaultProps = {
    Devices: [{id: '1', serial_no: '0', expires: 'ERROR! Please refresh this page'}, ],
}

Settings.propTypes = {
    User: PropTypes.object.isRequired,
    Devices: PropTypes.array.isRequired,
}

export default Settings
