import PropTypes from 'prop-types';
import axios from "axios";
import logo from '../assets/images/logo-white.png'
import imageLogin from '../assets/images/login-images/login-lock.jpg'
import FormInput from '../components/FormInput';

import '../assets/css/bootstrap.min.css';
import '../assets/css/icons.css';

const Login = ({ LoginSuccess, User }) => {
    
    let email = '';
    let password = '';
    let isDataValid = false;
    
    const emailChanged = (e) => {
        
        fieldChanged('email', e);
    }

    const pwdChanged = (e) => {
        
        fieldChanged('password', e);
    }

    function fieldChanged(key, value) {

        switch (key) {
            case 'email':
                email = value;                    
                break;
            case 'password':
                password = value;                    
                break;
            default:
                break;
        }
    }

    const LoginUser = (e) => {
        const url = encodeURI(process.env.REACT_APP_API_SERVER_URL + '/auth/');
        const credentials = btoa(email + ':' + password);
        const basicAuth = 'Basic ' + credentials;

        // clear the event
        e.preventDefault();
        
        axios.get(url, {
            // TODO: Remove the authorization from the parameters
            params: {
                "Authorization": basicAuth,
            }}, {
            headers: {
                "Authorization": basicAuth,
            }})
            .then(function (response) { return response}) 
            .then(function (response) {
                if (response.status === 200) {
                    // Set the state
                    User = response.data.data[0];
                    isDataValid = true;
                    LoginSuccess(User);
                }
            })
            .catch(function(error) {
                console.log('Error on Authentication', error);
                isDataValid = false;
                alert('ERROR: Please enter valid user/password');
            })
    }

    return (
    <>
        <div className="section-authentication">
            <div className="container-fluid">
                <div className="card mb-0">
                    <div className="card-body p-0">
                        <div className="row no-gutters">
                            <div className="col-12 col-lg-5 col-xl-4 d-flex align-items-stretch">
                                <div className="card mb-0 shadow-none bg-transparent w-100 login-card rounded-0">
                                    <div className="card-body p-md-5">
                                        <center><img src={logo} width="180" alt=""/></center>
                                        <h4 className="mt-5"><strong>Welcome Back</strong></h4>
                                        <p>Log in to your account using email & password</p>
                                        <div className="form-group mt-4">
                                            <FormInput name="email" 
                                                    labelText="Email Address" 
                                                    inputType="text"
                                                    value="" 
                                                    maxLen={50} 
                                                    isValid={isDataValid}
                                                    onValueChange={emailChanged} />
                                        </div>
                                        <div className="form-group">
                                            <FormInput name="password" 
                                                    labelText="Enter Password" 
                                                    inputType="password"
                                                    value="" 
                                                    maxLen={50} 
                                                    isValid={isDataValid}
                                                    onValueChange={pwdChanged} />
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col text-right">
                                                <a href="/forget_password"><i className='bx bxs-key mr-2'></i>Forget Password?</a>
                                            </div>
                                        </div>
                                        <button type="button" 
                                            className="btn btn-primary btn-block mt-3" 
                                            onClick={(e) => LoginUser(e)}>
                                                <i className='bx bxs-lock mr-1'></i>Login
                                        </button>
                                        <div className="text-center mt-4">
                                            <p className="mb-0">Don't have an account yet? <a href="https://wikimosque.com/prayer-times">Create an account</a></p>
                                            <p className="mb-0">Go back to <a href="/">Home</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-7 col-xl-8 d-flex align-items-stretch">
                                <div className="card mb-0 shadow-none bg-transparent w-100 rounded-0">
                                    <div className="card-body p-md-5">
                                        <div className="text-center">
                                            <img src={imageLogin} width="400px" className="img-fluid" alt=""/>
                                        </div>
                                        <h5 className="card-title">What is the Prayer Times Device?</h5>
                                        <p className="card-text">A smart device connected to your TV to turn your TV into a smart device 
                                                                    calling for the prayers with your preferred Azan Sound, 
                                                                    and listening to Quran recitation from best of the Reciters. 
                                                                    All configured and customized the way you like it!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/><br/>
    </>
    )
}

Login.propTypes = {
    LoginSuccess: PropTypes.func.isRequired,
    User: PropTypes.object,
}
export default Login;