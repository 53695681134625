import FormInput from '../components/FormInput';
import AudioPlayer from 'react-h5-audio-player';
import PropTypes from 'prop-types'
import { useState } from 'react';

const SettingAzan = ( { DeviceSetting, onSettingChange } ) => {
    
    // Enabled Components State
    const [azanEnabled, setAzanEnabled] = useState((DeviceSetting['azan']==='true'));
    const [azanSound, setAzanSound] = useState(`${process.env.REACT_APP_AUDIO_URL}/azan/${DeviceSetting['azan-sound']}.mp3`);
    const [azanDuaaState, setAzanDuaaState] = useState((DeviceSetting['azan-duaa']==='true') ? true : false);

    const azanList = [
        { id: 'aazan', description: 'Azan 1'}, 
        { id: 'afasi', description: 'Azan 2'}, 
        { id: 'afasi2', description: 'Azan 3'}, 
    ];

    const setSetting = (changedSettings) => {
        var tempSettings = [DeviceSetting, ];
            
        changedSettings.forEach(({setting, value}) => {
            try {
                // change the setting
                tempSettings[0][setting] = value;
            } catch (error) {
                console.log('ERROR: getSetting', setting, DeviceSetting, value, error);
            }
        });
        // call the Change State
        onSettingChange( tempSettings );
    }
    
    const onAzanChange = (e) => {

        // Update the Setting Change
        let settingArr = [{setting: 'azan', value: `${e}`}];
        if (!e) {
            // Change the Azan Duaa State
            setAzanDuaaState ( false );

            // Add it to the Setting array
            settingArr.push({setting: 'azan-duaa', value: 'false'});
        }
        setSetting(settingArr);
        setAzanEnabled( e );
    }

    const onAzanSoundChange = (e) => {
    const azanFile = `${process.env.REACT_APP_AUDIO_URL}/azan/${e}.mp3`;
        setSetting( [{setting: 'azan-sound', value: `${e}`}] );
        setAzanSound( azanFile );
    }

    const onAzanDuaaChange = (e) => {

        setAzanDuaaState ( e );
        setSetting([{setting: 'azan-duaa', value: `${e}`}]);
    }

    return (
        <>
        <div className="card col-md-12" key={`div-${DeviceSetting.device_id}`}>
            <div className="card-header">Azan
                <span className="float-right">
                    <FormInput name="azan" 
                        labelText="Enable Call for Prayer/Azan" 
                        inputType="checkbox"
                        value={DeviceSetting['azan']==='true' ? true : false} 
                        onValueChange={onAzanChange} />
                </span>
            </div>
            <div className={`card-body form-row ${!azanEnabled && 'd-none'}`} key={`div-e${azanEnabled}`}>
                <div className={`row p-0 w-100`}>
                    <div className="col-md-6 mb-3">
                        <FormInput name="azan-sound" 
                                labelText="Azan" 
                                inputType="select"
                                value={DeviceSetting['azan-sound']} 
                                options = {azanList}
                                maxLen={40} 
                                onValueChange={onAzanSoundChange} />
                    </div>
                    <div className="col-md-6 mb-3">
                        {(DeviceSetting != null) &&
                            <AudioPlayer 
                                id = "audio-player"
                                autoPlayAfterSrcChange = {false}
                                showJumpControls = {false}
                                src = {azanSound} 
                            />
                        }
                    </div>
                    <div className="col-md-12 mb-3">
                        <FormInput name="azan-duaa" 
                            labelText="Enable Duaa after Azan"
                            inputType="checkbox"
                            value={azanDuaaState} 
                            onValueChange={onAzanDuaaChange} />
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

SettingAzan.propTypes = {
    DeviceSetting: PropTypes.object.isRequired,
    onSettingChange: PropTypes.func.isRequired,
}

export default SettingAzan

