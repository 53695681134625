import FormInput from './FormInput';
import PropTypes from 'prop-types'
import { useEffect, useState, useCallback } from 'react';
import { getData } from '../global/func';

const SettingLocation = ( { DeviceKey, DeviceSetting, onSettingChange,  } ) => {
    // Define States
    const [countriesList, setCountriesList] = useState([{id: 0, description: 'Loading ...'}, ]);
    const [citiesList, setCitiesList] = useState([{id: 0, description: 'Loading ...'}, ]);
    const [curCountry, setCurCountry] = useState('');

    // Get the Cities List
    const getCitiesData = useCallback(async (country) => {
        const sAuthData = {
            DEVICE_KEY: DeviceKey,
            SESSION_ID: process.env.REACT_APP_SESSION_ID,
        };

        // Get Countries from API
        const url = encodeURI(process.env.REACT_APP_API_SERVER_URL + '/location/?COUNTRY=' + country);
        const cList = await getData(url, sAuthData, sAuthData);

        const newList = cList.map((e) => {
            return {id: e.id, description: e.city} 
        });
        
        // Update County/City List State
        setCitiesList( newList );
    }, [DeviceKey])

    const setSetting = (changedSettings) => {
        var tempSettings = [DeviceSetting, ];
            
        changedSettings.forEach(({setting, value}) => {
            try {
                // change the setting
                tempSettings[0][setting] = value;
            } catch (error) {
                console.log('ERROR: getSetting', setting, DeviceSetting, value, error);
            }
        });
        // call the Change State
        onSettingChange( tempSettings );
    }

    const onCountryChange = useCallback((e) => {
        // Read Cities 
        getCitiesData(e);

        // Change the state of curCountry
        setCurCountry( e );
    }, [getCitiesData])

    const onCityChange = (e) => {

        setSetting([{setting: 'location', value: `${e}`}]);
    }

    useEffect(() => {
        // Get the Countries List
        const getCurrentCountry = async (city_code) => {
            const sAuthData = {
                DEVICE_KEY: DeviceKey,
                SESSION_ID: process.env.REACT_APP_SESSION_ID,
            };

            // Get Countries from API
            const url = encodeURI(process.env.REACT_APP_API_SERVER_URL + '/location/?CITY=' + city_code);
            const cList = await getData(url, sAuthData, sAuthData);

            const selCountry = cList[0].country;

            // Update Cities
            onCountryChange(selCountry);
        }

        const getCountryData = async () => {
            const sAuthData = {
                DEVICE_KEY: DeviceKey,
                SESSION_ID: process.env.REACT_APP_SESSION_ID,
            };

            // Get Countries from API
            const url = encodeURI(process.env.REACT_APP_API_SERVER_URL + '/location/');
            const cList = await getData(url, sAuthData, sAuthData);

            const newList = cList.map((e) => {
                return {id: e.country, description: e.country} 
            });
            
            // Update County/City List State
            setCountriesList( newList );
        }
        
        // Get County of the selected Location
        getCurrentCountry(DeviceSetting['location']);

        // Get the data
        getCountryData();

    }, [DeviceKey, DeviceSetting, onCountryChange])

    return (
        <>
        <div className="card col-md-12" key={`div-${DeviceSetting.device_id}-${curCountry}`}>
            <div className="card-header">Device Location Information</div>
            <div className="card-body form-row">
                <div className="col-md-6 col-sm-12 mb-3">
                    <FormInput name="device-id" 
                            labelText="Country" 
                            inputType="select"
                            value={curCountry}
                            options = {countriesList}
                            onValueChange={onCountryChange} />
                </div>
                <div className="col-md-6 col-sm-12 mb-3">
                    <FormInput name="device-id" 
                            labelText="City" 
                            inputType="select"
                            value={DeviceSetting['location']}
                            options = {citiesList}
                            onValueChange={onCityChange} />
                </div>
            </div>
        </div>
        </>
    )
}

SettingLocation.propTypes = {
    DeviceSetting: PropTypes.object.isRequired,
    onSettingChange: PropTypes.func.isRequired,
    DeviceKey: PropTypes.string,
}

export default SettingLocation

