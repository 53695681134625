import { useState } from 'react'
import Login from './pages/Login';
import Settings from './pages/Settings';
import Copyrights from "./components/Footer";

import { getData } from './global/func';
import './assets/css/app.css';

const App = () => {

  // State 
  const [userInfo, setUserInfo] = useState({id: '0', name: '', AuthKey: '', });
  const [userDevices, setUserDevices] = useState({id: '0', serial_no: '', expires:'', });

  const LoginSuccess = async (userData) => {

    // Set the user state
    setUserInfo( userData );

    // Set the user devices state
    // Get devices from API
    const device_url = encodeURI(process.env.REACT_APP_API_SERVER_URL + '/devices/');
    const dAuthData = {
        USER_ID: userData.id,
        AUTH_KEY: userData.auth_key
    };
    
    let serverUserDevices = await getData(device_url, dAuthData, dAuthData);
    setUserDevices( serverUserDevices );
  }


  return (
      <div className="wrapper toggled">
		    <div className="page-wrapper">
          <div className = "page-content-wrapper">
            {
            (userInfo.id !== '0') && (userDevices.id !== '0') ?
            <>
              <Settings User={userInfo} Devices={userDevices} />
            </>
            :
            <>
              <Login LoginSuccess={LoginSuccess} User={userInfo} />
            </>
            }
            <Copyrights />
          </div>
        </div>
      </div>
  );
}

export default App;
