import axios from 'axios';

export const getData = async (url, params, headers) => {
var retValue;

    await axios.get(url, {params: params}, {headers: headers})
    .then((response) => {
        retValue = response.data.data;
        return retValue;
    })
    .then((resData) => {
        if (resData.status === 200) {
            // return the resData
            retValue = resData.data.data;
        }
    })
    .catch((error) => {
        console.log('Error on Authentication >>', error, url, params);
        return Promise.reject(error);
    })

    return retValue;
}

export const postData = async (url, bodyData, extraHeaders) => {
var retValue;
var bodyFormData = new FormData();

    for (let [key, value] of Object.entries(bodyData)) {
        
        bodyFormData.append(`${key}`, `${value}`);
    }    

    axios.post(url, bodyFormData, 
        {header: {"Content-Type": "multipart/form-data", ...extraHeaders, },
        params: extraHeaders})
    .then(function (response) {
      //handle success
      retValue = response;
    })
    .catch(function (error) {
      //handle error
      console.log('Error in post request > ', error);
    });


    return retValue;
}

export const getCountries = () => {
    return ([{id: 0, description: 'Canada'}, {id: 1, description: 'Egypt'}, ])
}

export const getCities = () => {
    return ([{id: 0, description: 'Edmonton'}, {id: 1, description: 'Cairo'}, ])
}
