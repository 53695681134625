import FormInput from './FormInput';
import AudioPlayer from 'react-h5-audio-player';
import PropTypes from 'prop-types'
import { useState } from 'react';

const SettingQuran = ( { DeviceSetting, onSettingChange } ) => {

    const [quranSound, setQuranSound] = useState(`${process.env.REACT_APP_AUDIO_URL}/${DeviceSetting['quran-sound']}/002185.mp3`);
    const [soundEnabled, setSoundEnabled] = useState((DeviceSetting['quran'] === 'true'), true, false);
    
    const reciterList = [
        { id: '11', description: 'Hossary - Moratal'}, 
        { id: '12', description: 'Hossary - Mojawad'}, 
        { id: '21', description: 'Minshawi - Moratal'}, 
        { id: '22', description: 'Minshawi - Mojawad'}, 
        { id: '31', description: 'Adbulbassit - Moratal'}, 
        { id: '32', description: 'Adbulbassit - Mojawad'}, 
    ];


    const setSetting = (changedSettings) => {
        var tempSettings = [DeviceSetting, ];
            
        changedSettings.forEach(({setting, value}) => {
            try {
                // change the setting
                tempSettings[0][setting] = value;
            } catch (error) {
                console.log('ERROR: getSetting', setting, DeviceSetting, value, error);
            }
        });
        // call the Change State
        onSettingChange( tempSettings );
    }
    
    const onQuranChange = (e) => {

        // Update the Setting Change
        let settingArr = [{setting: 'quran', value: `${e}`}];
        setSetting( settingArr );

        // Update the Enabled State
        setSoundEnabled( e );
    }

    const onReciterChange = (e) => {
        const quranSample = `${process.env.REACT_APP_AUDIO_URL}/${e}/002185.mp3`;
        
        setSetting( [{setting: 'quran-sound', value: `${e}`}] );

        setQuranSound( quranSample );
    }

    return (
        <>
        <div className="card col-md-12" key={`div-${DeviceSetting.device_id}`}>
            <div className="card-header">Recitation
                <span className="float-right">
                    <FormInput name="quran" 
                        labelText="Enable Recitation" 
                        inputType="checkbox"
                        value={DeviceSetting['quran']==='true' ? true : false} 
                        onValueChange={onQuranChange} />
                </span>
            </div>
            <div className={`card-body form-row ${!soundEnabled && 'd-none'}`} key={`div-q${soundEnabled}`}>
                <div className={`row p-0 w-100`}>
                    <div className="col-md-6 mb-3">
                        <FormInput name="quran-sound" 
                                labelText="Reciter" 
                                inputType="select"
                                value={DeviceSetting['quran-sound']} 
                                options = {reciterList}
                                maxLen={40} 
                                onValueChange={onReciterChange} />
                    </div>
                    <div className="col-md-6 mb-3">
                        {(DeviceSetting != null) &&
                            <AudioPlayer 
                                id = "audio-player"
                                autoPlayAfterSrcChange = {false}
                                showJumpControls = {false}
                                src = {quranSound} 
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

SettingQuran.propTypes = {
    DeviceSetting: PropTypes.object.isRequired,
    onSettingChange: PropTypes.func.isRequired,
}

export default SettingQuran

