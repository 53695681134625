import React from 'react'

const Footer = () => {
    return (
        <div className="footer px-md-5">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
                <p className="mb-0">WIKIMosque Prayer Times ver. {process.env.REACT_APP_VERSION} &copy; {new Date().getFullYear()} </p>
                <p><a href="https://eworx.ca" rel="noreferrer" target="_blank">eWorx Technology</a></p>
                <ul className="list-inline mb-0">
                    <li className="list-inline-item"><a href="https://wikimosque.com/privacy-policy">Privacy Policy</a></li>
                    <li className="list-inline-item"><a href="https://wikimosque.com/contact">Contact</a></li>
                </ul>
            </div>
        </div>
    )
}

export default Footer

